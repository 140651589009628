.date-menu {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    overflow: auto;
    scrollbar: none;
    max-width: 100vw;
    margin-bottom: 4px;

    &__disabled {
        pointer-events: none;

        & .date-menu__list {
            opacity: 0.5;
        }
    }

    &__list {
        display: flex;
        list-style-type: none;
        text-align: center;
        justify-content: space-between;
    }

    &__link {
        display: flex;
        flex-direction: column;

        text-align: center;
        border-radius: 6px;
        padding: 4px;

        &:link,
        &:visited {
            text-decoration: none;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
            background-color: $color__gray--lighter;
            //box-shadow: 0 3px 12px -2px rgba( $color__secondary, .85 );
        }
    }

    &__day {
        border-radius: 50%;
        //color: $color__text;
        text-align: center;
        font-size: 18px;
        font-weight: bolder;
        display: block;
        margin-bottom: 5px;
        line-height: 1;

        .active & {
            color: $color__secondary;
        }
    }

    &__weekday {
        display: block;
        color: $color__gray--dark;
        //font-weight: bold;
        font-size: 12px;
    }

    &__month,
    &__year {
        display: none;
        color: $color__gray--dark;
        font-size: 14px;
    }
    &__year {
        margin-left: 3px;
    }

    @media only screen and (min-width: 652px) {
        margin-bottom: 20px;

        &__item {
            margin-right: 10px;

            &:last-of-type {
                margin-right: 0;
            }
        }

        &__link {
            padding: 10px;
        }

        &__day {
            font-size: 20px;
        }

        &__weekday {
            font-size: 18px;
        }
    }

    @media only screen and (min-width: 1024px) {
        &__day {
            font-size: 44px;
        }

        &__month,
        &__year {
            display: inline-block;
        }
    }
}
