#app {
    max-width: 920px;
    margin: 0 auto;

    p {
        max-width: 610px;
    }

    h1 {
        color: $color__primary;
        @include font-definition__headline(1);
    }

    h2 {
        color: $color__primary;
        font-size: 18px;
        @include font-definition__headline(4);
        margin-bottom: 20px;
    }

    h3 {
        color: $color__primary;
        font-size: 18px;
        @include font-definition__headline(3);
    }
}
