/*
  ## Font Defition

// IE8 allows four(!) different weights + styles per font.
//
// @see http://blog.typekit.com/2011/06/27/new-from-typekit-variation-specific-font-family-names-in-ie-6-8/

  // @author Stephan Kroppenstedt <stephan.kroppenstedt@palasthotel.de>


*/
//@import url("https://use.typekit.net/alm6kxw.css");

$modularscale: (
    base: 14px,
    ratio: 1.125,
);

// ###### Font Families ######

$font__stack--system: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif;
$font__stack--paragraph: $font__stack--system;
$font__stack--blockquote: $font__stack--system;
$font__family--heading: $font__stack--system;

@mixin font-smoothing {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

$min_width: 320px;
$max_width: 980px;

@mixin font-definition__paragraph($size: null) {
    @include font-smoothing;
    font-family: $font__stack--paragraph;
    font-display: swap;
    line-height: 1.6;
    @include fluid-type($min_width, $max_width, ms(0), ms(1));

    @if $size == "small" {
        line-height: 1.4;
        @include fluid-type($min_width, $max_width, ms(0), ms(1));
    }

    @if $size == "smaller" {
        line-height: 1.5;
        @include fluid-type($min_width, $max_width, ms(0), ms(0));
    }
}

@mixin font-definition__headline($level: 1) {
    @include font-smoothing;
    line-height: 1.1;
    font-family: $font__family--heading;
    font-display: swap;

    font-weight: 700;

    @if $level == 0 {
        @include fluid-type($min_width, $max_width, ms(5), ms(12));
    }
    @if $level == 1 {
        @include fluid-type($min_width, $max_width, ms(5), ms(7));
    }
    @if $level == 2 {
        @include fluid-type($min_width, $max_width, ms(3), ms(5));
    }
    @if $level == 3 {
        @include fluid-type($min_width, $max_width, ms(2), ms(4));
    }
    @if $level == 4 {
        @include fluid-type($min_width, $max_width, ms(1), ms(3));
    }
    @if $level == 5 {
        @include fluid-type($min_width, $max_width, ms(0), ms(2));
    }
    @if $level == 6 {
        @include fluid-type($min_width, $max_width, ms(2), ms(2));
    }
}

@mixin font-definition__display($level: 1) {
    @include font-smoothing;
    line-height: 1.5;
    font-family: $font__stack--paragraph;
    font-display: swap;
    font-weight: 700;

    @if $level == 1 {
        @include fluid-type($min_width, $max_width, ms(5), ms(9));
    }
    @if $level == 2 {
        @include fluid-type($min_width, $max_width, ms(4), ms(6));
    }
    @if $level == 3 {
        @include fluid-type($min_width, $max_width, ms(3), ms(5));
    }
    @if $level == 4 {
        @include fluid-type($min_width, $max_width, ms(2), ms(4));
    }
    @if $level == 5 {
        @include fluid-type($min_width, $max_width, ms(1), ms(1));
    }
    @if $level == 6 {
        @include fluid-type($min_width, $max_width, ms(0), ms(0));
    }
}
