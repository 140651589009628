$color__primary: rgb(63, 70, 75);
$color__secondary: #2e5c05;

$color__black--0: rgba(0, 0, 0, 0.87);
$color__black--1: rgba(0, 0, 0, 0.54);
$color__black--2: rgba(0, 0, 0, 0.38);
$color__black--3: rgba(0, 0, 0, 0.26);
$color__black--overlay: rgba(0, 0, 0, 0.5);

$color__gray--lighter: rgb(245, 246, 248);
$color__gray--light: rgb(190, 190, 190);
$color__gray: rgb(125, 125, 125);
$color__gray--dark: #666;
$color__gray--darker: #333;

$color__shadow: rgba(0, 0, 0, 0.4);
$color__shadow--light: rgba(0, 0, 0, 0.075);

$color__divider--dark: black;

$color__primary--light: lighten($color__primary, 10%);
$color__primary--dark: darken($color__primary, 20%);
$color__primary--shadow: desaturate(lighten($color__primary, 10%), 10%);

$color__primary-invert--light: white;
$color__primary-invert: white;
$color__primary-invert--dark: white;
$color__sand: rgb(240, 236, 235);

$color__blue: $color__primary;
$color__blue--dark: rgb(76, 94, 101);

// Page colors
$color__page-background: rgba(240, 232, 235, 0.3);
$color__header-background: rgb(255, 255, 255);
$color__site-background: white;
$color__site-border: rgb(228, 228, 228);
$color__footer-background: rgb(76, 94, 101);
$color__footer-font-color: rgb(255, 255, 255);

// Text Colors

$color__headline: rgb(74, 74, 74);
$color__text: rgb(25, 25, 25);
$color__text--light: rgb(140, 140, 140);

// Form colors

$color__form--border: #cfcfcf;
$color__form--background: rgba($color__primary, 0.1);

$color__form--submit: rgb(127, 184, 29);

$color__form--error: #d61d1d;
$color__form--warning: orange;
$color__form--success: green;

$color__button--default: darken($color__gray--light, 0%);

$color__button--green: rgb(163, 167, 57);
$color__button--green-invert: white;
$color__button--red: rgb(233, 84, 49);
$color__button--red-invert: white;
$color__button--highlight: $color__primary;
$color__button--highlight--invert: white;

// Social colors from @url http://brandcolors.net

$color__social--facebook: #3b5998;
$color__social--twitter: #55acee;
$color__social--whatsapp: #4dc247;
$color__social--googleplus: #dd4b39;
$color__social--pinterest: #cc2127;
$color__social--tumblr: #35465c;
$color__social--youtube: #cd201f;
$color__social--vimeo: #1ab7ea;
$color__social--soundcloud: #ff8800;
$color__social--linkedin: #0077b5;
$color__social--xing: #cfdc00;
$color__social--disqus: #2e9fff;
$color__social--instagram: #3f729b;
$color__social--snapchat: #fffc00;
$color__social--pocket: rgb(232, 36, 68);

$color__social--print: #ff8800;
$color__social--mail: #4b9b86;
