@import "../../style/styleguide/colors";

.session-header {
    display: flex;
    align-items: center;
    width: min(610px, 100%);

    .session-header__pre-event-badge,
    .session-header__tip-badge {
        box-sizing: border-box;
        height: 18px;
        background-color: $color__secondary;
        color: white;
        font-weight: 900;
        font-size: 10px;
        line-height: 16px;
        padding: 1px 5px;
        text-transform: uppercase;
        display: inline-block;
        border-radius: 2px;
        margin-right: 8px;
        letter-spacing: 0.015em;
    }

    .session-header__title {
        margin-right: 10px;
        font-weight: 600;
    }
}

.session-cancelled {
    box-sizing: border-box;
    background-color: darken(rgba(255, 0, 0, 1), 5%);
    color: white;
    border-radius: 2px;
    display: inline-block;
    padding: 3px 8px;
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: -10px;
}
