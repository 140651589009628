.session-meta-list {
    margin-left: 0;
    margin-bottom: 20px;
    list-style-type: none;
    line-height: 1.8;
}

.session-list {
    list-style-type: none;
    width: 100%;
    max-width: 640px;

    &__date {
        margin: 20px 0 0;
    }

    &-item {
        margin-bottom: 1rem;

        &__link {
            border-radius: 6px;
            display: block;
            padding: 15px;

            &:link,
            &:visited {
                text-decoration: none;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: $color__gray--lighter;
            }

            position: relative;
        }

        &__headline {
            font-weight: bold;
            font-size: 22px;
            margin-right: 20px;
        }

        &__host {
            font-size: 15px;
            color: $color__gray;
            display: block;
        }

        &__location {
            color: $color__gray;
            font-size: 15px;
        }

        &__date {
            color: $color__gray;
            font-size: 15px;
            margin-right: 10px;
        }
    }
}

.session-list-description {
    display: block;
    font-size: 15px;
}

.session-cta {
    font-weight: bold;
    font-size: 90%;
    text-decoration: underline;
    color: $color__secondary;
}
