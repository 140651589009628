.session-page {
    display: flex;
    flex-direction: column;
}

.session-page__host {
    display: flex;
    border-top: 1px dashed $color__gray--light;
    padding-top: 20px;
    margin-top: 20px;
}

.session-page__host-logo {
    max-width: 280px;
    height: auto;
    margin-top: 25px;
    margin-right: 25px;
    min-width: 40px;

    img {
        width: 100%;
        height: auto;
    }
}

.session-page__host .session-page__host-title {
    font-size: 10px;
    letter-spacing: 0.045em;
    text-transform: uppercase;
    color: $color__gray--light;
}

.session-page__host .session-page__host-headline {
    font-size: 26px;
    margin-top: -10px;
}

.session-data {
    border-top: 1px dashed rgb(190, 190, 190);
    padding-top: 20px;
    margin-top: 20px;

    display: flex;
}

.session-description-short {
    font-size: 140%;
    width: min(610px, 100%);
}

.session-header,
.session-description-short,
.session-description-long {
    align-self: center;
}

.session-map {
    width: 50%;
    margin-right: 0;
    margin-left: auto;
}

//.host-link {
//  a {
//    //display: block;
//    //margin-top: -10px;
//    font-size: 15px;
//    //font-weight: 600;
//
//    &:link, &:visited {
//      color: darken( $color__primary, 10% );
//      text-decoration: underline;
//    }
//    &:hover, &:focus, &:active {
//      text-decoration: none;
//    }
//
//    &:before {
//      //color: lighten( $color__primary, 10% );
//
//      content: "↗  ";
//      transform: rotate(45deg);
//      display: inline-block;
//      font-size: 90%;
//      margin-right: 6px;
//    }
//  }
//}
.session-page__host-social {
    margin-top: 8px;
    display: flex;
    font-size: 14px;
    font-weight: bolder;

    img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        opacity: 0.4;
        transition: all 333ms ease-in-out;
    }

    a {
        &:link,
        &:visited {
            text-decoration: none;
        }
        &:hover,
        &:focus,
        &:active {
            img {
                opacity: 1;
            }
        }
    }
}

.missing-event {
    padding: 8px 25px;

    background-color: $color__secondary;
    border-radius: 3px;
    transition: all 333ms ease-in-out;
    //display: inline-block;
    border: 1px solid darken($color__secondary, 5%);
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    &:link,
    &:visited {
        color: white;
        font-weight: 600;
        font-size: 15px;
        text-decoration: none;
    }

    &:hover,
    &:focus,
    &:active {
        background-color: darken($color__secondary, 2%);
        transform: scale(1.015);
        box-shadow: 0 8px 8px -8px rgba(33, 33, 33, 0.4);
    }
}

.job-notification {
    //padding: 5px 10px;
    //border-radius: 3px;
    //display: inline-block;
    //margin-top: 10px;
    //margin-bottom: 10px;
    ////background-color: rgba($color__secondary, .05 );
    ////border: 1px solid rgba( $color__secondary, .3 );
    //
    //text-decoration: none;
    ////font-weight: bold;
    //font-size: 14px;
    //
}

.session-page__links {
    a {
        margin-right: 10px;
        transition: all 333ms ease-in-out;

        &:link,
        &:visited {
            color: $color__gray--darker;
            text-decoration: underline;
            text-decoration-color: $color__gray--light;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;

            &:before {
                color: $color__secondary;
            }
        }
    }
}

.session-page__links-text {
    a {
        margin-right: 20px;
        &:before {
            content: "↗  ";
            transform: rotate(45deg) translateY(-2px);
            display: inline-block;
            font-size: 80%;
            margin-right: 6px;
            color: $color__gray;
        }
    }
}

.sessions-list-daily {
    display: flex;
    flex-direction: column;
    align-items: center;

    .session-list-item__date-full {
        display: none;
    }
}

.session-section__accessibilityHint {
    margin-top: 1em;
}
