a {
    transition: all 333ms ease-in-out;

    &:link {
        color: $color__primary;

        .inverted & {
            color: white;
        }
    }
    &:visited {
        color: $color__primary;

        .inverted & {
            color: white;
        }
    }

    @media (min-width: 960px) {
        &:hover {
            text-decoration: none;
        }
    }
}
