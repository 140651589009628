h2 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 0;
}
h3 {
    @include font-definition__headline(6);
}

h4 {
    @include font-definition__headline(4);
}

h5 {
    @include font-definition__headline(5);
}

h6 {
    @include font-definition__headline(6);
}
