.organizer-page {
    display: flex;
    flex-direction: column;

    &__header,
    & .host-link,
    & .session-page__host-social,
    &__infotext {
        align-self: center;
        width: min(610px, 100%);
    }

    & .session-list {
        align-self: center;

        border-top: 1px dashed rgb(190, 190, 190);
        padding-top: 20px;
        margin-top: 20px;
    }
}
