@import "../../style/styleguide/colors";

.header__back-button {
    font-size: 13px;
    font-weight: bolder;
    padding: 10px 20px;
    border-radius: 3px;
    border: 1px solid white;
    background-color: $color__gray--lighter;

    &:before {
        content: "\2190  ";
        margin-right: 5px;
    }

    cursor: pointer;
    transition: all 333ms ease-in-out;

    &:hover,
    &:active,
    &:focus {
        background-color: $color__secondary;
        color: white;
    }
}
