body {
    background-color: $color__page-background;
    font-size: 1rem;
    line-height: 24px;
    @include font-definition__paragraph(small);
    color: $color__text;
}

body {
    background-color: white;
    color: black;
}
