.channel-filter {
    display: flex;
    margin-bottom: 30px;
    border-top: 1px solid #bebebe;
    border-bottom: 1px solid #bebebe;

    li {
        display: inline-block;
    }
}

.channel-filter__side {
    flex: 1;
    padding: 15px;
    min-height: 100px;
}

.channel-filter__title {
    color: #666;
    font-size: 14px;
    font-weight: bold;
}

.channel-filter__none {
    color: #666;
    font-size: 14px;
}
