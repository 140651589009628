.channel-badge {
    box-sizing: border-box;
    height: 17px;
    font-size: 11px;
    font-weight: bold;
    margin-right: 10px;
    padding: 3px 6px;
    line-height: 1;
    border-radius: 4px;
    display: inline-block;
    color: white;
    border: none;

    &-1 {
        // "Digitale Gesellschaft"
        background-color: #d5a301 !important;
    }
    &-2 {
        // "Science \u0026 Tech"
        background-color: #007cb3 !important;
    }
    &-3 {
        // "Business"
        background-color: #45c4b0 !important;
    }
    &-4 {
        // "Nachhaltigkeit"
        background-color: #a0c21d !important;
    }
    &-5 {
        // "Gr\u00fcnden\/Start-up"
        background-color: #04aa99 !important;
    }
    &-6 {
        // "(New) Work\/HR"
        background-color: #14b3c9 !important;
    }
    &-7 {
        // "Surprise"
        background-color: #cb0114 !important;
    }
    &-8 {
        // "Futur II"
        background-color: #a00ecc !important;
    }
}

.channel-badge--interactive {
    cursor: pointer;
}

.channel-badge--inactive {
    background-color: #bebebe !important;
}
