.search-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    &__input {
        flex-grow: 1;
        padding: 0.5rem 1rem;
        border: 1px solid $color__gray--light;
        border-radius: 0.5rem;
        font-size: 1rem;

        &:focus {
            outline: none;
            border-color: #000;
        }
    }
}
